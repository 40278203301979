<script lang="ts">
    import {FormEntry} from "@shared/Form/interface"
    import FormTypeErrors from "@shared/Form/FormTypeErrors.svelte"
    import DatePicker from "@shared/DatePicker/DatePicker.svelte"
    import {CalendarEvent} from "@shared/DatePicker/utils"
    import {DateTime} from "luxon"

    export let entry: FormEntry<any, {
        range: boolean
        events: CalendarEvent[]
        inline: boolean
    }, any[], {
        from: FormEntry
        to: FormEntry
        date: FormEntry
    }>

    $: inputNames = entry.extra.range ? [
        entry.children.from.fullName,
        entry.children.to.fullName,
    ] : entry.children.date.fullName

    $: inputValues = entry.extra.range ? [
        entry.children.from.value ? DateTime.fromSQL(entry.children.from.value).setLocale("cs") : DateTime.now().setLocale("cs"),
        entry.children.to.value ? DateTime.fromSQL(entry.children.to.value).setLocale("cs") : DateTime.now().setLocale("cs"),
    ] : entry.children.date.value ? [DateTime.fromSQL(entry.children.date.value).setLocale("cs")] : undefined
</script>

{#if !(entry.disabled && entry.value === "")}
    <div {...entry.rowAttr} class="flex flex-col {entry.rowAttr?.class ?? ''}">
        {#if entry.label !== null}
            <label class="font-bold text-neutral-800 relative" {...entry.labelAttr}>
                {@html entry.label}
                {#if !entry.disabled && entry.required}
                    <span class="absolute ml-1 text-red-800">*</span>
                {/if}
            </label>
        {/if}

        <DatePicker
                values={inputValues}
                entry={entry}
                range={entry.extra.range}
                events={entry.extra.events}
                inputName={inputNames}
                inline={entry.extra.inline}
        />
    </div>
{/if}