import {DateTime} from "luxon"
import parser from "any-date-parser"
import Translator from "bazinga-translator"

export const defaultMonths = {
    1: Translator.trans("january"),
    2: Translator.trans("february"),
    3: Translator.trans("march"),
    4: Translator.trans("april"),
    5: Translator.trans("may"),
    6: Translator.trans("june"),
    7: Translator.trans("july"),
    8: Translator.trans("august"),
    9: Translator.trans("september"),
    10: Translator.trans("october"),
    11: Translator.trans("november"),
    12: Translator.trans("december"),
}

export const defaultDays = [
    Translator.trans("days.short.monday"),
    Translator.trans("days.short.tuesday"),
    Translator.trans("days.short.wednesday"),
    Translator.trans("days.short.thursday"),
    Translator.trans("days.short.friday"),
    Translator.trans("days.short.saturday"),
    Translator.trans("days.short.sunday"),
]

export type SelectedDates = {
    selectedFrom: DateTime
    selectedTo: DateTime
    click: boolean
    enter: boolean
}

export type CalendarEvent = {
    id: number | string
    tooltip: string | null
    color: string
}

export const parseDate = (string: string): DateTime | null => {
    const date = parser.attempt(string)
    if (date.invalid) {
        let day = parseInt(string)
        if(isNaN(day) || day < 1) {
            return null
        }

        const now = DateTime.now()
        if(now.daysInMonth < day) {
            return null
        }

        return now.set({day})
    }

    return DateTime.fromObject(date)
}